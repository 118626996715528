import { Controller } from "stimulus"
import Cleave from "cleave.js"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["table", "row", "commitment", "select", "loader"]

  connect() {
    this.choicesOptions = {
      itemSelectText: "",
      allowHTML: true,
      searchEnabled: true,
      searchResultLimit: 100,
    }

    this.commitmentTargets.forEach((el) => {
      new Cleave(el, {
        numeral: true,
      })
    })

    this.selectTargets.forEach((el) => {
      new Choices(el, this.choicesOptions)
    })

    this.nextIndex = $(this.tableTarget).find("tbody tr").length + 1

    $(this.element).on("blur", "[name='investments[][commitment]']", (e) => {
      const $input = $(e.currentTarget)
      const $container = $input.closest("td")
      const value = $input.val().replace(/,/g, "")

      $input.removeClass("error")
      $container.find(".b-onboarding-field-error").remove()

      if (value !== "" && parseInt(value) % 25000 !== 0) {
        $input.addClass("error")
        $container.append(
          '<div class="b-onboarding-field-error">Commitment must be a multiple of $25,000</div>',
        )
      }
    })
  }

  onAdd(e) {
    const $button = $(e.currentTarget)
    const $newRow = $(this.rowTarget.innerHTML)
    const $table = $(e.target).closest("table")
    const $tbody = $table.find("tbody")
    const $lastRow = $tbody.find("tr").last()

    $tbody.append($newRow)

    const $commitment = $newRow.find("[name='investments[][commitment]']")
    $commitment.attr("id", `commitment_${this.nextIndex}`)
    new Cleave($commitment.get(0), {
      numeral: true,
    })

    const $investorEntity = $newRow.find(
      "[name='investments[][investor_entity_id]']",
    )
    $investorEntity.attr("id", `investor_entity_${this.nextIndex}`)
    new Choices($investorEntity.get(0), this.choicesOptions)

    const $security = $newRow.find("[name='investments[][security_id]']")
    $security.attr("id", `security_${this.nextIndex}`)
    const choices = new Choices($security.get(0), this.choicesOptions)

    if ($lastRow.length) {
      const value = $lastRow.find("[name='investments[][security_id]']").val()
      choices.setChoiceByValue(value)
    }

    this.nextIndex++
    e.preventDefault()
    $button.blur()
  }

  onRemove(e) {
    $(e.target).closest("tr").remove()
  }
}
