import { Controller } from "stimulus"
import Skyflow from "skyflow-js"
import {
  displaySkyflowError,
  isSkyflowMissingFileError,
} from "../util/displaySkyflowError"

export default class extends Controller {
  static targets = ["container", "uploadButton", "skipButton"]

  connect() {
    this.$container = $(this.containerTarget)
    this.fileFields = JSON.parse(this.element.dataset.fields)

    if (!SKYFLOW_VAULT_ID) {
      console.error("SKYFLOW_VAULT_ID is not set")
    }
    if (!SKYFLOW_VAULT_URL) {
      console.error("SKYFLOW_VAULT_URL is not set")
    }

    this.skyflowClient = Skyflow.init({
      vaultID: SKYFLOW_VAULT_ID, // ID of the vault that the client should connect to.
      vaultURL: SKYFLOW_VAULT_URL, // URL of the vault that the client should connect to.
      getBearerToken: () => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: this.element.dataset.tokenUrl,
            type: "GET",
            success: function (data) {
              resolve(data)
            },
            error: function (error) {
              reject(error)
            },
          })
        })
      },
      options: {
        logLevel:
          RAILS_ENV === "development"
            ? Skyflow.LogLevel.DEBUG
            : Skyflow.LogLevel.ERROR,
      },
    })

    this.skyflowContainer = this.skyflowClient.container(
      Skyflow.ContainerType.COLLECT,
    )
    this.collectElements = {}
    this.readyCount = Object.keys(this.fileFields).length

    if (this.readyCount > 0) {
      Object.keys(this.fileFields).forEach((k) => {
        this.$container.append(`<tr>
          <td>${this.fileFields[k].label}</td>
          <td>${this.fileFields[k].required ? "Yes" : "No"}</td>
          <td id="link${k}">Checking...</td>
          <td class="b-onboarding-file" id="file${k}"></td>
        </tr>`)

        let tableName = "aml"
        let columnName = k

        if (k.startsWith("license")) {
          tableName = "users"
          columnName = "license"
        }

        this.collectElements[k] = {
          filled: false,
          required: this.fileFields[k].required,
          element: this.skyflowContainer.create({
            table: tableName, //the table this data belongs to
            column: columnName, //the column into which this data should be inserted
            skyflowID: this.fileFields[k].skyflow_id, // the skyflow_id of the record
            type: Skyflow.ElementType.FILE_INPUT, //Skyflow.ElementType enum
            inputStyles: {
              base: {
                fontSize: "14px",
                marginBottom: "0",
              },
            }, //optional styles that should be applied to the form element
            labelStyles: {
              base: {
                fontSize: "14px",
              },
            }, //optional styles that will be applied to the label of the collect element
            errorTextStyles: {}, //optional styles that will be applied to the errorText of the collect element
          }),
        }

        this.collectElements[k].element.on(Skyflow.EventName.READY, (state) => {
          this.incrementReadyCount()
        })

        this.collectElements[k].element.on(
          Skyflow.EventName.CHANGE,
          (state) => {
            this.collectElements[k].filled = !state.isEmpty

            if (!state.isEmpty) {
              $(this.uploadButtonTarget).html("Upload & Submit")
            }

            this.updateButtonState(false)
          },
        )

        this.collectElements[k].element.mount(`#file${k}`)
      })
    } else {
      this.updateButtonState(true)
    }
  }

  incrementReadyCount() {
    this.readyCount -= 1
    if (this.readyCount <= 0) {
      $.get(this.element.dataset.filesUrl, (response) => {
        Object.keys(this.collectElements).forEach((k) => {
          const $td = $(`#link${k}`)
          if (response.hasOwnProperty(k)) {
            this.collectElements[k].filled = true
            $td.html(
              `Attached (<a href="${response[k]}" target="_blank">view</a>)`,
            )
          } else {
            $td.html("-")
          }
        })

        this.updateButtonState(true)
      })
    }
  }

  updateButtonState(initial) {
    let requiredFieldsFilled = true
    const $uploadButton = $(this.uploadButtonTarget)

    Object.values(this.collectElements).forEach((v) => {
      requiredFieldsFilled = requiredFieldsFilled && (!v.required || v.filled)
    })

    if (initial) {
      if (requiredFieldsFilled) {
        $uploadButton.html("Submit Onboarding")
      }
      $uploadButton.show()
      $(this.skipButtonTarget).show()
    }

    $uploadButton.prop("disabled", !requiredFieldsFilled)
  }

  onSubmit() {
    if (Object.keys(this.fileFields).length > 0) {
      this.skyflowContainer
        .uploadFiles()
        .then((response) => {
          $.post(this.element.dataset.filesUrl)
        })
        .catch((err) => {
          let fatal = null

          if (err.hasOwnProperty("errorResponse")) {
            for (const e of err.errorResponse) {
              if (!isSkyflowMissingFileError(e)) {
                fatal = e
                break
              }
            }
          } else {
            fatal = err
          }

          if (fatal) {
            displaySkyflowError(fatal)
            throw err
          } else {
            $.post(this.element.dataset.filesUrl)
          }
        })
    } else {
      $.post(this.element.dataset.filesUrl)
    }
  }
}
