// @ts-check
import { Controller } from "stimulus"
import Cleave from "cleave.js"
import {
  Chart,
  LineController,
  LinearScale,
  CategoryScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  BarController,
  BarElement,
} from "chart.js"
import { calculateMixedGraphValues } from "helpers"

Chart.register(
  LinearScale,
  LineController,
  CategoryScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  BarController,
  BarElement,
)

export default class BudgetDetailsController extends Controller {
  static targets = [
    "editButton",
    "cancelButton",
    "commitment",
    "commitmentInput",
    "commitmentsChart",
    "commitments",
    "chart",
    "tableControl",
    "graphControl",
    "section",
    "toggles",
    "legendItem",
    "controls",
    "topSection",
  ]

  static values = {
    data: Object,
    riskScenario: Object,
  }

  connect() {
    if (this.hasCommitmentInputTarget) {
      this.editing = false
      this.commitmentInputTargets.forEach((field) => {
        new Cleave(field, {
          numeral: true,
        })
      })
    }

    // sets a TS type to more easily understand shape of data
    let budget_data = /** @type {import("../types").BudgetData} */ (
      this.dataValue
    )

    /** @type {import("../types").RiskScenario | undefined} */
    let risk_scenario = this.hasRiskScenarioValue
      ? this.riskScenarioValue
      : undefined

    if (this.hasTopSectionTarget) {
      this.labels = Array.from(Array(15).keys()).map((i) => `${i + 1}`)
      this.axisFormatter = Intl.NumberFormat("en-US", { notation: "compact" })
      this.dollarFormatter = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
      })

      // ordering matters.  dollar graphs must appear before percent graphs
      // in the list, with the split fairMarketValue as the last dollar graph
      this.graphTypes = [
        {
          graph: "privateCapitalBudget",
          datasets: [
            {
              data: this.dataValue.nav.slice(1).map((v) => Math.round(v)),
              label: "Total Net Asset Value",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "projectedAnnualCommitments",
          datasets: [
            {
              data: this.dataValue.total_commitments.map((v) => Math.round(v)),
              label: "Projected Annual Commitments",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "netCashFlow",
          datasets: [
            {
              data: this.dataValue.private_cash
                .slice(1)
                .map((v) => Math.round(v)),
              label: "Annual Net Cash Flow",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "unfundedCommitments",
          datasets: [
            {
              data: this.dataValue.private_unfunded
                .slice(1)
                .map((v) => Math.round(v)),
              label: "Cumulative Unfunded Commitments",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "capitalCalls",
          datasets: [
            {
              data: this.dataValue.private_cost
                .slice(1)
                .map((v) => Math.round(v * -1)),
              label: "Annual Capital Calls (Gross)",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "inOutflows",
          datasets: [
            {
              data: inOutflowsGraphData(budget_data, risk_scenario),
              label: "In/Outflows",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "fairMarketValue",
          datasets: [
            {
              data: this.dataValue.public_fmv
                .slice(1)
                .map((v) => Math.round(v)),
              label: "Public FMV",
              stack: "FMV",
              axisType: "dollar",
            },
            {
              data: this.dataValue.private_fmv
                .slice(1)
                .map((v) => Math.round(v)),
              label: "Private FMV",
              stack: "FMV",
              breePresetColor: "#D7EEFD",
              axisType: "dollar",
            },
          ],
        },
        {
          graph: "currentAllocations",
          datasets: [
            {
              data: Object.values(this.dataValue.allocations)
                .reduce(
                  (acc, array) => acc.map((sum, i) => sum + array[i + 1]),
                  new Array(15).fill(0),
                )
                .map((v) => Math.round(v * 1000) / 10),
              label: "Current Allocations (Private)",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "targetAllocations",
          datasets: [
            {
              data: new Array(15).fill(
                Math.round(
                  Object.values(this.dataValue.targets).reduce(
                    (a, b) => a + parseFloat(b),
                    0,
                  ) * 10,
                ) / 10,
              ),
              label: "Target Allocations (Private)",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "stressTest",
          datasets: [
            {
              data: this.dataValue.assumed_returns
                .map((v) => parseFloat(v))
                .map((v) => (isNaN(v) ? 0 : Math.round(v * 1000) / 10)),
              label: "Return/Stress Test Assumptions",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "grossCashFlowNav",
          datasets: [
            {
              data: capitalCallsNavRatioGraphData(budget_data),
              label: "Capital Calls as % of NAV",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "grossCashFlowPublic",
          datasets: [
            {
              data: capitalCallsPublicRatioGraphData(budget_data),
              label: "Capital Calls as % of Public",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "netCashFlowRatioOfNav",
          datasets: [
            {
              data: netCashFlowRatioOfNavGraphData(budget_data),
              label: "Net Cash Flow as % of NAV",
              axisType: "percent",
            },
          ],
        },
        {
          graph: "netCashFlowRatioOfPublic",
          datasets: [
            {
              data: netCashFlowRatioOfPublicGraphData(budget_data),
              label: "Net Cash Flow as % of Public",
              axisType: "percent",
            },
          ],
        },
      ]

      this.drawChart()
    }
  }

  drawChart() {
    if (this.chart) {
      this.chart.destroy()
      this.chart = null
    }

    const scales = {
      y: {
        beginAtZero: true,
        grid: {
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          padding: 10,
          callback: (value, index, ticks) => {
            if (this.primaryAxisType == "percent") {
              return value + "%"
            } else {
              return this.axisFormatter.format(value)
            }
          },
        },
      },
      x: {
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          padding: 10,
          callback: (value, index, ticks) => {
            return this.labels[index]
          },
        },
      },
    }

    const selectedGraphs = this.graphControlTargets
      .filter((el) => el.classList.contains("checked"))
      .map((el) =>
        this.graphTypes.find(
          (graphType) => graphType.graph == el.dataset.graph,
        ),
      )

    this.legendItemTargets.forEach((el) => $(el).hide())

    if (selectedGraphs.length == 0) {
      return
    }

    selectedGraphs.sort(
      (a, b) => this.graphTypes.indexOf(a) - this.graphTypes.indexOf(b),
    )

    const datasets = []

    selectedGraphs.forEach((graph) => datasets.push(...graph.datasets))

    this.primaryAxisType = datasets[0].axisType

    datasets.forEach((dataset) => {
      dataset.categoryPercentage = 0.85
      dataset.barPercentage = 1.0
      dataset.yAxisID = "y"
      dataset.type = "bar"
    })

    const barColors = ["#184683", "#348BFE"]
    let colorIndex = 0

    datasets.forEach((dataset, i) => {
      let barColor = dataset.breePresetColor
      if (!barColor) {
        barColor = barColors[colorIndex]
        colorIndex += 1
      }
      dataset.backgroundColor =
        dataset.borderColor =
        dataset.hoverBackgroundColor =
          barColor

      // can't figure out how to change the spacing of chartjs legends, so
      // we're doing this a super hacky way external to chartjs

      $(this.legendItemTargets[i])
        .css("display", "flex")
        .find("span")
        .text(datasets[i].label)
        .parent()
        .find("div")
        .css("backgroundColor", barColor)
    })

    const lastDataset = datasets[datasets.length - 1]

    // check if we need to add a second y axis for percentages
    if (datasets[0].axisType !== lastDataset.axisType) {
      const { pMin, pMax, step, dTickSize } = calculateMixedGraphValues(
        datasets
          .filter((dataset) => dataset.axisType == "dollar")
          .reduce((acc, dataset) => (acc = acc.concat(dataset.data)), []),
        datasets[datasets.length - 1].data,
      )

      // alter left axis accordingly
      scales.y.min = (pMin / step) * dTickSize
      scales.y.max = (pMax / step) * dTickSize
      scales.y.ticks.stepSize = dTickSize

      // add the second axis
      scales.y1 = {
        beginAtZero: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        min: pMin,
        max: pMax,
        ticks: {
          stepSize: step,
          padding: 10,
          callback: (value, index, ticks) => {
            return value + "%"
          },
        },
      }

      // adjust percent dataset to be a line graph using the right axis
      lastDataset.yAxisID = "y1"
      lastDataset.type = "line"
    }

    const chartOptions = {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: {
            radius: 0,
          },
        },
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        plugins: {
          tooltip: {
            mode: "index",
            boxPadding: 3,
            itemSort: (a, b) => {
              return b.datasetIndex - a.datasetIndex
            },
            callbacks: {
              title: (context) => {
                return `Year ${context[0].label}`
              },
              label: (context) => {
                var label = context.dataset.label || ""

                if (label) {
                  if (context.dataset.axisType == "percent") {
                    label += ": " + context.formattedValue + "%"
                  } else {
                    label += ": " + this.dollarFormatter.format(context.raw)
                  }
                }
                return label
              },
            },
          },
        },
        scales: scales,
      },
    }

    this.chart = new Chart(this.chartTarget, chartOptions)
  }

  filterTables() {
    this.tableControlTargets.forEach((tableControlEl) => {
      const showTable = tableControlEl.classList.contains("checked")
      this.sectionTargets
        .filter((el) => el.dataset.section == tableControlEl.dataset.table)
        .forEach((el) => $(el).toggle(showTable))
    })
  }

  // stimulus actions

  onTableCheck(e) {
    $(e.currentTarget).toggleClass("checked")
    this.filterTables()
  }

  onGraphCheck(e) {
    if (e.currentTarget.classList.contains("checked")) {
      e.currentTarget.classList.remove("checked")
      this.togglesTarget.classList.remove("full")
      if (
        this.graphControlTargets.filter((el) =>
          el.classList.contains("checked"),
        ).length == 0
      ) {
        this.topSectionTarget.classList.add("empty")
      }
      this.drawChart()
    } else if (!this.togglesTarget.classList.contains("full")) {
      e.currentTarget.classList.add("checked")
      this.topSectionTarget.classList.remove("empty")
      if (
        this.graphControlTargets.filter((el) =>
          el.classList.contains("checked"),
        ).length == 2
      ) {
        this.togglesTarget.classList.add("full")
      }
      this.drawChart()
    }
  }

  onEdit(e) {
    e.preventDefault()
    if (this.editing) {
      $(this.commitmentInputTargets).each((i, e) => {
        $(e).val($(e).val().replace(/,/g, ""))
      })
      $(this.element).find("form").submit()
    } else {
      this.editing = true
      $(this.editButtonTarget)
        .text("Save Changes")
        .siblings(".b-budget-collapse")
        .hide()
        .parent()
        .removeClass("collapsed")
      $(this.cancelButtonTarget).show()
      $(this.commitmentTargets).hide()
      $(this.commitmentInputTargets).show()
    }
  }

  onCancel(e) {
    e.preventDefault()
    window.location.reload()
  }

  onCollapse(e) {
    e.preventDefault()
    $(e.currentTarget).blur().parent().toggleClass("collapsed")
  }

  onToggleControls() {
    $(this.controlsTarget).toggleClass("closed")
  }
}

/**
 * Capital Calls as % of NAV
 * @see https://github.com/TanookiLabs/BREE/blob/ff07069ce64397e36dc8ed7a3ded7682052c178c/app/views/budgets/_budget_data_table.haml#L290-L294
 * @param {import("../types").BudgetData} budget_data
 */
function capitalCallsNavRatioGraphData(budget_data) {
  let privateCostItems = budget_data.private_cost.slice(1)
  let navItems = budget_data.nav.slice(1)

  // - InvestorEntity::PROJECTED_YEARS.times do |i|
  //   %td #{"%.1f" % (@budget_data[:private_cost][i+1] / @budget_data[:nav][i+1] * -100)}%

  return privateCostItems
    .map((privateCost, i) => {
      let nav = navItems[i]
      return isValidDivisor(nav) ? (privateCost / nav) * -1 : null
    })
    .map(convertDecimalToPercent)
}

/**
 * Capital Calls as % of Public
 * @see https://github.com/TanookiLabs/BREE/blob/ff07069ce64397e36dc8ed7a3ded7682052c178c/app/views/budgets/_budget_data_table.haml#L300-L304
 * @param {import("../types").BudgetData} budget_data
 */
function capitalCallsPublicRatioGraphData(budget_data) {
  let privateCostItems = budget_data.private_cost.slice(1)
  let navItems = budget_data.public_fmv.slice(1)

  return privateCostItems
    .map((privateCost, i) => {
      let nav = navItems[i]
      return isValidDivisor(nav) ? (privateCost / nav) * -1 : null
    })
    .map(convertDecimalToPercent)
}

/**
 * Net Cash Flow (% NAV)
 * @param {import("../types").BudgetData} budget_data
 * @see https://github.com/TanookiLabs/BREE/blob/54fbade59466e5c6d32c2ff70d61ea74df4d9571/app/views/budgets/_budget_data_table.haml#L295-L299
 * @returns {Array<number|null>}
 */
function netCashFlowRatioOfNavGraphData(budget_data) {
  let privateCashItems = budget_data.private_cash.slice(1)
  let navItems = budget_data.nav.slice(1)

  return privateCashItems
    .map((privateCash, i) => {
      let nav = navItems[i]
      return isValidDivisor(nav) ? privateCash / nav : null
    })
    .map(convertDecimalToPercent)
}
/**
 * Net Cash Flow (% Public)
 * @param {import("../types").BudgetData} budget_data
 * @see https://github.com/TanookiLabs/BREE/blob/040bff31a67de3fdee677152c02efec114045679/app/views/budgets/_budget_data_table.haml#L305-L309
 * @returns {Array<number|null>}
 */
function netCashFlowRatioOfPublicGraphData(budget_data) {
  let privateCashItems = budget_data.private_cash.slice(1)
  let publicFmvItems = budget_data.public_fmv.slice(1)

  return privateCashItems
    .map((privateCash, i) => {
      let publicFmv = publicFmvItems[i]
      return isValidDivisor(publicFmv) ? privateCash / publicFmv : null
    })
    .map(convertDecimalToPercent)
}

/**
 * In/Outflows
 * @param {import("../types").BudgetData} budget_data
 * @param {import("../types").RiskScenario | undefined} risk_scenario
 * @see https://github.com/TanookiLabs/BREE/blob/6afecda7a4ccc91f116849b530b0cae46179e463/app/views/budgets/_budget_data_table.haml#L12-L19
 * @returns {Array<number|null>}
 */
function inOutflowsGraphData(budget_data, risk_scenario) {
  // - @budget_data[:inflows].each_with_index do |inflow, i|
  //   - calculated_inflow = inflow * 1000
  //   - if @risk_scenario
  //     - calculated_inflow += @budget_data[:nav][i] * @risk_scenario.data['inflows'][i].to_f / 100.0
  //   %td= number_with_delimiter((calculated_inflow / 1000.0).round)
  return budget_data.inflows
    .map((inflow, i) => {
      let calculated_inflow = inflow * 1000
      if (risk_scenario) {
        calculated_inflow +=
          (budget_data.nav[i] * parseFloat(risk_scenario.data.inflows[i])) /
          100.0
      }
      return calculated_inflow
    })
    .map((v) => Math.round(v))
}

/**
 * @param {number | null | undefined} divisor
 * @returns {boolean}
 */
function isValidDivisor(divisor) {
  return divisor !== 0 && divisor != null && !Number.isNaN(divisor)
}

/**
 * @param {number | null} decimal
 * @returns {number | null}
 */
function convertDecimalToPercent(decimal) {
  if (decimal == null) {
    return null
  }
  return Math.round(decimal * 1000) / 10
}

/**
 * @param {number | null} value
 * @returns {number | null}
 */
function replaceNonNumbersWithNull(value) {
  return Number.isFinite(value) ? value : null
}
