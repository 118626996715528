import { Controller } from "stimulus"

// Connects to data-controller="auto-submit"
export default class extends Controller {
  connect() {}

  submit(ev) {
    let form = ev.target.form
    form.submit()
  }
}
