import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = ["pages", "download", "checkbox"]
  static values = {
    pagesUrl: String,
    downloadUrl: String,
  }

  connect() {
    this.loadPages()
  }

  loadPages() {
    const exclusions = this.checkboxTargets
      .filter((el) => !el.checked)
      .map((el) => el.name)
    let url = this.pagesUrlValue
    if (exclusions.length) {
      url += `?exclude=${exclusions.join(",")}`
    }
    this.pagesTarget.setAttribute("src", url)
    $(this.downloadTarget).toggleClass(
      "disabled",
      ["summary", "investments", "budget", "progress"].every((k) =>
        exclusions.includes(k),
      ),
    )
  }

  onFilterChange() {
    this.loadPages()
  }

  onDownload() {
    const exclusions = this.checkboxTargets
      .filter((el) => !el.checked)
      .map((el) => el.name)

    $(this.downloadTarget).hide()
    $(this.element).find(".loader").css("display", "inline-block")

    $.ajax({
      url: this.downloadUrlValue,
      type: "GET",
      data: {
        exclude: exclusions.join(","),
      },
      success: (response) => {
        const channel = consumer.subscriptions.create(
          {
            channel: "ReportChannel",
            id: response.id,
          },
          {
            received: (data) => {
              if (data == "Error") {
                alert(
                  "Error processing report.  Please contact an administrator.",
                )
              } else {
                const tempEl = document.createElement("a")
                document.body.appendChild(tempEl)
                tempEl.style = "display: none"
                tempEl.href = data
                tempEl.download = response.filename
                tempEl.disposition = "attachment"
                tempEl.click()

                $(this.downloadTarget).show()
                $(this.element).find(".loader").hide()
              }
              channel.unsubscribe()
            },
          },
        )
      },
      error: () => {
        alert("Error building report, please contact an administrator.")
      },
    })
  }
}
