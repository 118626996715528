import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

/**
 * based on https://www.stimulus-components.com/docs/stimulus-chartjs/
 */
export default class ChartController extends Controller {
  static targets = ["canvas"]
  static values = {
    type: {
      type: String,
      default: "line",
    },
    data: Object,
    options: Object,
    yAxisPercentage: Boolean,
  }

  connect() {
    const el = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(el.getContext("2d"), {
      type: this.typeValue,
      data: this.chartData,
      options: this.chartOptions,
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  get chartData() {
    return (
      this.hasDataValue ||
        console.warn("You need to pass data as JSON to see the chart."),
      this.dataValue
    )
  }

  get chartOptions() {
    let base = {
      ...this.defaultOptions,
      ...this.optionsValue,
    }

    return {
      ...base,
      ...(this.hasYAxisPercentageValue && {
        scales: {
          ...base.scales,
          y: {
            ...base.scales?.y,
            ticks: {
              ...base.scales?.y?.ticks,
              callback: (value) => {
                return `${value}%`
              },
            },
          },
        },
      }),
    }
  }

  get defaultOptions() {
    return {}
  }
}
