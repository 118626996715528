import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggler", "menu"]

  connect() {
    this.menuHeight = $(this.menuTarget).outerHeight()
  }

  onToggle() {
    $(this.menuTarget).height(
      Math.max($("body > main").outerHeight(), this.menuHeight),
    )
    $("body").toggleClass("mobile-menu")
  }
}
