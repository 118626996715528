import { Controller } from "stimulus"
import {
  Chart,
  LineController,
  LinearScale,
  CategoryScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  BarController,
  BarElement,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import { calculateMixedGraphValues } from "helpers"

Chart.register(
  LinearScale,
  LineController,
  CategoryScale,
  LineElement,
  PointElement,
  Filler,
  Tooltip,
  BarController,
  BarElement,
  annotationPlugin,
)

// https://www.chartjs.org/docs/3.3.2/samples/advanced/linear-gradient.html
let width, height, gradient
function getGradient(context) {
  const chart = context.chart
  const { ctx, chartArea } = chart

  if (!chartArea) {
    return null
  }

  const chartWidth = chartArea.right - chartArea.left
  const chartHeight = chartArea.bottom - chartArea.top
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth
    height = chartHeight
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
    gradient.addColorStop(0, "rgba(0, 0, 0, 0)")
    gradient.addColorStop(1, "rgba(20,55,100,0.25)")
  }

  return gradient
}

export default class extends Controller {
  static targets = [
    "navChart",
    "liquidityChart",
    "allocationChart",
    "commitmentsChart",
  ]

  static values = {
    data: Object,
  }

  connect() {
    const assetClasses = {
      equity: {
        label: "Private Equity",
        color: "#184683",
      },
      venture_capital: {
        label: "Venture Capital",
        color: "#338CFF",
      },
      real_assets: {
        label: "Private Real Assets",
        color: "#61BFFD",
      },
      credit: {
        label: "Private Credit",
        color: "#A8DBFF",
      },
      special_situations: {
        label: "Private Special Situations",
        color: "#D7EEFD",
      },
    }

    const labels = Array.from(Array(15).keys()).map((i) => `${i + 1}`)

    const axisFormatter = Intl.NumberFormat("en-US", { notation: "compact" })
    const dollarFormatter = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    })

    this.charts = []

    if (this.hasNavChartTarget) {
      this.charts.push(
        new Chart(this.navChartTarget, {
          type: "line",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Private FMV",
                data: this.dataValue.private_fmv
                  .slice(1)
                  .map((v) => Math.round(v)),
                borderColor: "#184683",
                backgroundColor: "#184683",
                borderWidth: 2,
                fill: true,
              },
              {
                label: "Public FMV",
                data: this.dataValue.public_fmv
                  .slice(1)
                  .map((v) => Math.round(v)),
                borderColor: "#D7EEFD",
                backgroundColor: "#D7EEFD",
                borderWidth: 2,
                fill: true,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 0,
              },
            },
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false,
            },
            plugins: {
              tooltip: {
                mode: "index",
                boxPadding: 3,
                itemSort: (a, b) => {
                  return b.datasetIndex - a.datasetIndex
                },
                callbacks: {
                  title: (context) => {
                    return `Year ${context[0].label}`
                  },
                  label: function (context) {
                    var label = context.dataset.label || ""

                    if (label) {
                      label += ": " + dollarFormatter.format(context.raw)
                    }
                    return label
                  },
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return axisFormatter.format(value)
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return labels[index]
                  },
                },
              },
            },
          },
        }),
      )
    }

    if (this.hasAllocationChartTarget) {
      const privateTarget =
        Math.round(
          Object.values(this.dataValue.targets).reduce(
            (sum, a) => sum + parseFloat(a),
            0,
          ) * 10,
        ) / 10
      this.charts.push(
        new Chart(this.allocationChartTarget, {
          type: "line",
          data: {
            labels: ["", ...labels],
            datasets: Object.entries(assetClasses).map(([k, v]) => {
              const d = {
                label: v.label,
                data: this.dataValue.allocations[k].map(
                  (v) => Math.round(v * 1000) / 10,
                ),
                borderColor: v.color,
                backgroundColor: v.color,
                borderWidth: 2,
                fill: true,
              }

              return d
            }),
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 0,
              },
            },
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false,
            },
            plugins: {
              tooltip: {
                mode: "index",
                boxPadding: 3,
                itemSort: (a, b) => {
                  return b.datasetIndex - a.datasetIndex
                },
                callbacks: {
                  title: (context) => {
                    if (context[0].label) {
                      return `Year ${context[0].label}`
                    } else {
                      return "Budget"
                    }
                  },
                  label: function (context) {
                    var label = context.dataset.label || ""

                    if (label) {
                      label += ": " + context.formattedValue + "%"
                    }
                    return label
                  },
                },
              },
              annotation: {
                annotations: {
                  line1: {
                    type: "line",
                    yMin: privateTarget,
                    yMax: privateTarget,
                    borderColor: "#143764",
                    borderWidth: 2,
                    borderDash: [6, 2],
                  },
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                },
                grace: 1,
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return value + "%"
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return index == 0 ? "" : labels[index - 1]
                  },
                },
              },
            },
          },
        }),
      )
    }

    if (this.hasCommitmentsChartTarget) {
      this.charts.push(
        new Chart(this.commitmentsChartTarget, {
          type: "line",
          data: {
            labels: labels,
            datasets: Object.entries(assetClasses).map(([k, v]) => {
              const d = {
                label: v.label,
                data: this.dataValue.commitments[k],
                borderColor: v.color,
                backgroundColor: v.color,
                borderWidth: 2,
                fill: true,
              }

              return d
            }),
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 0,
              },
            },
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false,
            },
            plugins: {
              tooltip: {
                mode: "index",
                boxPadding: 3,
                itemSort: (a, b) => {
                  return b.datasetIndex - a.datasetIndex
                },
                callbacks: {
                  title: (context) => {
                    return `Year ${context[0].label}`
                  },
                  label: function (context) {
                    var label = context.dataset.label || ""

                    if (label) {
                      label += ": " + dollarFormatter.format(context.raw)
                    }
                    return label
                  },
                },
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return axisFormatter.format(value)
                  },
                },
              },
              x: {
                grid: {
                  display: false,
                  drawTicks: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return labels[index]
                  },
                },
              },
            },
          },
        }),
      )
    }

    if (this.hasLiquidityChartTarget) {
      const pCapitalCalls = this.dataValue.private_cost
        .slice(1)
        .map((v, i) => Math.round((v / this.dataValue.nav[i + 1]) * -1000) / 10)
      const pCashFlows = this.dataValue.private_cash
        .slice(1)
        .map((v, i) => Math.round((v / this.dataValue.nav[i + 1]) * 1000) / 10)
      const cashFlows = this.dataValue.private_cash
        .slice(1)
        .map((v) => Math.round(v))
      const capitalCalls = this.dataValue.private_cost
        .slice(1)
        .map((v) => Math.round(v) * -1)

      const filteredCashFlows = pCashFlows.filter((v) => !Number.isNaN(v))
      const filteredCapitalCalls = pCapitalCalls.filter((v) => !Number.isNaN(v))

      const { pMax, pMin, step, dTickSize } = calculateMixedGraphValues(
        [...capitalCalls, ...cashFlows],
        [...filteredCapitalCalls, ...filteredCashFlows],
      )

      this.charts.push(
        new Chart(this.liquidityChartTarget, {
          type: "line",
          data: {
            labels: labels,
            datasets: [
              {
                label: "Net Cash Flow",
                data: cashFlows,
                backgroundColor: "#184683",
                borderColor: "#184683",
                borderWidth: 0,
                type: "bar",
                yAxisID: "y",
              },
              {
                label: "Net Capital Calls",
                data: capitalCalls,
                backgroundColor: "#e5e5e5",
                borderColor: "#e5e5e5",
                borderWidth: 0,
                type: "bar",
                yAxisID: "y",
              },
              {
                label: "Capital Calls as % of NAV",
                data: pCapitalCalls,
                borderColor: "#AFDCFC",
                backgroundColor: "#AFDCFC",
                borderWidth: 2,
                yAxisID: "y1",
              },
              {
                label: "Net Cash Flow as % of NAV",
                data: pCashFlows,
                borderColor: "#81B0D2",
                backgroundColor: "#81B0D2",
                borderWidth: 2,
                yAxisID: "y1",
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 0,
              },
            },
            interaction: {
              mode: "nearest",
              axis: "x",
              intersect: false,
            },
            plugins: {
              tooltip: {
                mode: "index",
                boxPadding: 3,
                itemSort: (a, b) => {
                  return b.datasetIndex - a.datasetIndex
                },
                callbacks: {
                  title: (context) => {
                    return `Year ${context[0].label}`
                  },
                  label: function (context) {
                    var label = context.dataset.label || ""

                    if (label) {
                      if (context.datasetIndex <= 1) {
                        label += ": " + dollarFormatter.format(context.raw)
                      } else {
                        label += ": " + context.formattedValue + "%"
                      }
                    }
                    return label
                  },
                },
              },
              annotation: {
                annotations: {
                  line1: {
                    type: "line",
                    yMin: 0,
                    yMax: 0,
                    borderColor: "#143764",
                    borderWidth: 2,
                    drawTime: "beforeDatasetsDraw",
                  },
                },
              },
            },
            scales: {
              y1: {
                beginAtZero: true,
                position: "right",
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false,
                  drawBorder: false,
                },
                min: pMin,
                max: pMax,
                ticks: {
                  stepSize: step,
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return value + "%"
                  },
                },
              },
              y: {
                beginAtZero: true,
                position: "left",
                grid: {
                  drawTicks: false,
                  drawBorder: false,
                },
                min: (pMin / step) * dTickSize,
                max: (pMax / step) * dTickSize,
                ticks: {
                  stepSize: dTickSize,
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return axisFormatter.format(value)
                  },
                },
              },
              x: {
                grid: {
                  drawTicks: false,
                  display: false,
                },
                ticks: {
                  padding: 10,
                  callback: (value, index, ticks) => {
                    return labels[index]
                  },
                },
              },
            },
          },
        }),
      )
    }
  }
}
