import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.loading = false
    this.hasMore = true

    if (this.element.hasAttribute("data-autoload")) {
      this.page = 0
      this.onLoadMore()
    } else {
      this.page = 1
    }

    $("html").on("load-more", this.onLoadMore.bind(this))
  }

  onLoadMore() {
    if (this.hasMore && !this.loading) {
      this.loading = true
      $.get(this.element.dataset.url, { page: this.page + 1 }, (response) => {
        if (response && response.trim().length) {
          $(this.element).append(response)
          this.page = this.page + 1
        } else {
          this.hasMore = false
        }
        this.loading = false
      })
    }
  }

  disconnect() {
    $("html").off("load-more")
  }
}
