import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["uploadPanel", "submit", "fileInput"]

  connect() {
    this.fileRequired = true
  }

  onRadioChange(e) {
    this.fileRequired = ["replace", "add", "upload"].includes(
      e.currentTarget.value,
    )
    $(this.uploadPanelTarget).toggle(this.fileRequired)
    this.updateSubmitButton()
  }

  updateSubmitButton() {
    this.submitTarget.disabled =
      this.fileRequired && this.fileInputTarget.value == ""
  }

  onSubmit() {
    if (!this.fileRequired) {
      this.fileInputTarget.remove()
    }
  }
}
