import { Controller } from "stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
  static targets = ["select", "search", "results"]

  connect() {
    useClickOutside(this)
    this.opened = false
    this.contextData = null

    this.$results = $(this.resultsTarget)

    $(this.element).on("click", ".context-expander", (e) => {
      const $result = $(e.currentTarget).closest(".context-result")
      $result.siblings().removeClass("expanded")
      $result.toggleClass("expanded")
    })

    $(this.element).on("click", ".context-link", (e) => {
      const cid = $(e.currentTarget).data("cid")
      if (cid == "unset") {
        window.setCID()
      } else {
        window.setCID(cid)
      }
      location.reload()
    })

    $(this.searchTarget).on("change keyup", this.renderResults.bind(this))
  }

  get dropdownOpen() {
    return this.element.classList.contains("open")
  }

  toggle() {
    if (this.dropdownOpen) {
      this.closeDropdown()
    } else {
      this.openDropdown()
    }
  }

  openDropdown() {
    this.element.classList.add("open")
    this.searchTarget.value = ""
    this.searchTarget.focus()

    if (!this.opened) {
      $.get(this.element.dataset.url, (response) => {
        this.contextData = response
        this.renderResults()
      })
      this.opened = true
    } else {
      this.renderResults()
    }
  }

  closeDropdown() {
    this.element.classList.remove("open")
  }

  escapeRegex(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  }

  renderResults() {
    if (this.contextData === null) {
      return
    }

    const regex = new RegExp(
      `\\b${this.escapeRegex(this.searchTarget.value)}`,
      "i",
    )

    const filteredResults = { others: [] }

    filteredResults.households = this.contextData.households
      .filter((household) => regex.test(household.name))
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    filteredResults.households.forEach((household) => {
      household.entities = this.contextData.entities
        .filter((entity) => entity.household == household.id)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    })

    if (this.searchTarget.value != "") {
      filteredResults.others = this.contextData.entities
        .filter((entity) => regex.test(entity.name))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    }

    if (this.contextData.hasOwnProperty("advisors")) {
      filteredResults.others = filteredResults.others.concat(
        this.contextData.advisors
          .filter((user) => regex.test(user.name))
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      )
    }

    if (this.contextData.hasOwnProperty("groups")) {
      filteredResults.others = filteredResults.others.concat(
        this.contextData.groups
          .filter((group) => regex.test(group.name))
          .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
      )
    }

    this.$results.empty()

    if (
      this.searchTarget.value == "" &&
      this.contextData.households.length > 1
    ) {
      this.$results.append(
        `<div class="context-result"><div class="context-result-content"><div class="context-link" data-cid="unset">All Entities</div></div></div>`,
      )
    }

    filteredResults.households.forEach((household) => {
      // don't display households with no entities
      if (household.entities.length == 0) {
        return
      }

      const $el = $(
        `<div class="context-result"><div class="context-result-content"><div class="context-expander"></div><div class="context-link" data-cid="${household.cid}">${household.svg}<div class="context-name">${household.name}</div></div></div></div>`,
      )

      if (household.prospect) {
        $el.find(".context-name").append('<div class="context-prospect"></div>')
      }

      if (
        (this.contextData.households.length == 1 &&
          filteredResults.others.length == 0) ||
        (this.searchTarget.value == "" &&
          this.element.dataset.cid &&
          household.entities
            .map((ent) => ent.cid)
            .includes(this.element.dataset.cid))
      ) {
        $el.addClass("expanded")
      }

      household.entities.forEach((ent) => {
        const $entity = $(
          `<div class="context-inner"><div class="context-link" data-cid="${ent.cid}">${ent.svg}<div class="context-name">${ent.name}</div></div></div>`,
        )
        if (ent.prospect) {
          $entity
            .find(".context-name")
            .append('<div class="context-prospect"></div>')
        }
        $el.append($entity)
      })
      this.$results.append($el)
    })

    filteredResults.others.forEach((ent) => {
      this.$results.append(
        `<div class="context-result"><div class="context-result-content"><div class="context-link" data-cid="${ent.cid}">${ent.svg}<span>${ent.name}</span></div></div></div>`,
      )
    })
  }

  clickOutside(e) {
    this.closeDropdown()
  }
}
