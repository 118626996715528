import { Controller } from "stimulus"
import { Dropzone } from "dropzone"
import consumer from "../channels/consumer"
import Choices from "choices.js"
import Skyflow from "skyflow-js"

export default class extends Controller {
  static targets = [
    "typeSelect",
    "uploadGroup",
    "yearGroup",
    "quarterGroup",
    "dateGroup",
    "fundNameGroup",
    "submitLeft",
    "resume",
    "form",
    "right",
    "status",
    "table",
    "body",
    "entitySelect",
    "skyflow",
    "submitRight",
    "formRight",
    "addButton",
    "templateLink",
  ]

  connect() {
    this.communicationType = this.typeSelectTarget.value

    this.skyflowClient = Skyflow.init({
      vaultID: SKYFLOW_VAULT_ID,
      vaultURL: SKYFLOW_VAULT_URL,
      getBearerToken: () => {
        return new Promise((resolve, reject) => {
          $.ajax({
            url: this.element.dataset.tokenUrl,
            type: "GET",
            success: function (data) {
              resolve(data)
            },
            error: function (error) {
              reject(error)
            },
          })
        })
      },
      options: {
        logLevel:
          RAILS_ENV === "development"
            ? Skyflow.LogLevel.DEBUG
            : Skyflow.LogLevel.ERROR,
      },
    })

    this.skyflowContainer = this.skyflowClient.container(
      Skyflow.ContainerType.COLLECT,
    )

    this.convertTargets()

    $(this.formTarget).ajaxForm({
      beforeSubmit: () => {
        this.$status
          .html(
            this.communicationType == "k1s" ? "Creating..." : "Uploading...",
          )
          .show()
        this.$yearGroup.find("select").prop("disabled", true)
        this.$quarterGroup.find("select").prop("disabled", true)
        this.$dateGroup.find("input").prop("disabled", true)
        this.$fundNameGroup.find("select").prop("disabled", true)
        this.$submitLeft.hide()
      },
      success: (response) => {
        const $response = $(response)
        this.$right.append($response)
        this.convertTargets()

        if (this.communicationType == "k1s") {
          this.$status.html("Upload K-1s")
        } else {
          this.$status.html("Initiating...")

          let rowsFound = 0

          this.subscription = consumer.subscriptions.create(
            {
              channel: "CommunicationChannel",
              id: this.tableTarget.dataset.communication,
            },
            {
              connected: () => {
                this.$status.html("Processing spreadsheet, please wait...")
              },
              received: (data) => {
                if (data == "Complete") {
                  this.$status.html(
                    `Processing complete, ${rowsFound} document${
                      rowsFound == 1 ? "" : "s"
                    } generated.`,
                  )
                  if (rowsFound == 0) {
                    this.$tbody.html(
                      '<tr><td colspan="4">Could not match any rows for processing.</td></tr>',
                    )
                  } else {
                    this.updateSubmitRightState()
                    this.$body.show()
                  }
                } else {
                  rowsFound += 1
                  this.$tbody.append(data)
                }
              },
            },
          )
        }
      },
      error: () => {
        alert("Error creating communication object.")
      },
    })
  }

  convertTargets() {
    // converts targets into this.${target}
    this.constructor.targets.forEach((target) => {
      if (
        this[
          "has" + target.charAt(0).toUpperCase() + target.slice(1) + "Target"
        ]
      ) {
        this[`$${target}`] = $(this[`${target}Target`])
      }
    })

    this.skyflowTargets.forEach((target) => {
      const skyflowID = target.dataset.skyflow
      if (typeof skyflowID !== "undefined") {
        this.createSkyflowElement(skyflowID)
      }
    })

    this.$tbody = this.$right.find("tbody")

    if (this.hasEntitySelectTarget) {
      this.entityChoices = new Choices(this.entitySelectTarget, {
        searchEnabled: true,
        searchResultLimit: 100,
        itemSelectText: "",
        allowHTML: true,
      })

      this.$entitySelect.on("change", () => {
        const value = this.$entitySelect.val()
        this.$entitySelect
          .closest(".b-communications-add-entity")
          .find("button")
          .prop("disabled", !value || !value.length)
      })
    }
  }

  createSkyflowElement(skyflowId) {
    const targetId = `#skyflow${skyflowId}`
    const $target = $(targetId)

    const skyflowElement = this.skyflowContainer.create({
      table: "documents",
      column: "file",
      skyflowID: skyflowId,
      type: Skyflow.ElementType.FILE_INPUT,
      inputStyles: {
        base: {
          fontSize: "14px",
          marginBottom: "0",
        },
      },
    })

    skyflowElement.on(Skyflow.EventName.CHANGE, (state) => {
      if (!state.isEmpty) {
        $target.closest("tr").addClass("filled")
        this.updateSubmitRightState()
      }
    })

    $target.html("")
    skyflowElement.mount(targetId)
    $target.data("skyflow", undefined)
  }

  updateSubmitRightState() {
    if (this.hasTableTarget) {
      if (this.tableTarget.classList.contains("k1")) {
        const filledCount = this.skyflowTargets.filter((target) =>
          $(target).closest("tr").hasClass("filled"),
        ).length
        this.$submitRight
          .val(`Send Selected Documents (${filledCount})`)
          .prop(
            "disabled",
            filledCount == 0 || filledCount != this.skyflowTargets.length,
          )
      } else {
        const filledCount = this.$table.find("input:checked").length
        this.$submitRight
          .val(`Send Selected Documents (${filledCount})`)
          .prop("disabled", filledCount == 0)
      }
    }
  }

  onAddK1(e) {
    e.preventDefault()

    const value = this.$entitySelect.val()
    this.entityChoices.setChoiceByValue("")
    this.$entitySelect.change()

    this.$addButton.find("> span").hide()
    this.$addButton.find(".loader").show()

    $.ajax({
      method: "POST",
      url: this.$entitySelect.data("url"),
      data: { investor_entity: value },
      success: (response) => {
        const $response = $(response)
        this.$tbody.children().last().before($response)
        this.updateSubmitRightState()
        this.$addButton.find("> span").show()
        this.$addButton.find(".loader").hide()
        this.createSkyflowElement(
          $response.find("[data-skyflow]").data("skyflow"),
        )
      },
    })
  }

  onDeleteK1(e) {
    const $target = $(e.currentTarget)
    e.preventDefault()
    $.ajax({ url: $target.data("url"), method: "DELETE" })
    $target.closest("tr").remove()
    this.updateSubmitRightState()
  }

  onSelectType() {
    if (this.hasResumeTarget) {
      this.$resume.hide()
    }

    this.communicationType = this.typeSelectTarget.value

    this.typeSelectTarget.disabled = true

    $(
      `<input type="hidden" name="communication_type" value="${this.communicationType}">`,
    ).insertAfter(this.typeSelectTarget)

    if (this.communicationType == "k1s") {
      this.$yearGroup.show()
      this.$submitLeft.val("Create Communication").prop("disabled", false)
    } else {
      this.$uploadGroup.show()
      this.$submitLeft.val("Process Spreadsheet")

      if (this.communicationType == "statements") {
        this.$yearGroup.show()
        this.$quarterGroup.show()
      } else {
        this.$dateGroup.show()
      }

      this.templateLinkTarget.href += this.communicationType
    }
    this.$submitLeft.show()
  }

  onCheckboxChange() {
    this.updateSubmitRightState()
  }

  // to circumvent some crazy browser problem where label "for" isn't linking
  // to the checkbox when loaded via ajax
  onLabelClick(e) {
    $(e.currentTarget).parent().find("input").click()
  }

  onSubmitRight(e) {
    if (this.communicationType == "k1s") {
      e.preventDefault()
      this.skyflowContainer
        .uploadFiles()
        .then((response) => {
          this.formRightTarget.submit()
        })
        .catch((err) => {
          let fatal = false

          // need to account for file inputs that were "Remove"d, which Skyflow still looks for

          if (err.hasOwnProperty("errorResponse")) {
            err.errorResponse.forEach((e) => {
              if (
                !e.error.description ||
                !e.error.description.startsWith("No Files found in the request")
              ) {
                fatal = true
              }
            })
          } else {
            fatal = true
          }

          if (fatal) {
            alert(
              "There was a problem uploading your files.  Please ensure all files are valid and below the required file size.",
            )
          } else {
            this.formRightTarget.submit()
          }
        })
    }
  }
}
