const qaLogger = (output) => {
  try {
    if (process.env.RAILS_ENV !== "development") return null
    console.log(`[QA] - ${output}`)
  } catch (error) {
    console.error("[QA Logger Error]:", error)
  }
}

export default qaLogger
