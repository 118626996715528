export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling)
}

export function calculateMixedGraphValues(decimalValues, percentValues) {
  const dMax = Math.max(...decimalValues, 0)
  const dMin = Math.min(...decimalValues, 0)

  let pMax = Math.max(...percentValues, 0)
  let pMin = Math.min(...percentValues, 0)

  if (dMin < 0) {
    if (dMax > 0) {
      pMin = Math.min(pMin, (pMax * dMin) / dMax)
    } else if (pMax > 0) {
      pMin = Math.min(pMin, pMax * -1)
    }
  }

  if (dMax > 0) {
    if (dMin < 0) {
      pMax = Math.max(pMax, (pMin * dMax) / dMin)
    } else if (pMin < 0) {
      pMax = Math.max(pMax, pMin * -1)
    }
  }

  pMin = Math.floor(pMin)
  pMax = Math.ceil(pMax)

  const step = Math.ceil((pMax - pMin) / 8)

  // raise/lower the bounds for a clean number of above/below zero
  pMin = Math.floor(pMin / step) * step
  pMax = Math.ceil(pMax / step) * step

  // Now find the ideal tick size for max and min and use the larger
  let dTickSize = 0
  if (dMax > 0) {
    let unroundedTickSize = dMax / (pMax / step)
    let x = Math.ceil(Math.log10(unroundedTickSize) - 1)
    let pow10x = 10 ** x
    dTickSize = Math.ceil(unroundedTickSize / pow10x) * pow10x
  }

  if (dMin < 0) {
    let unroundedTickSize = dMin / (pMin / step)
    let x = Math.ceil(Math.log10(unroundedTickSize) - 1)
    let pow10x = 10 ** x
    dTickSize = Math.max(
      dTickSize,
      Math.ceil(unroundedTickSize / pow10x) * pow10x,
    )
  }

  return { dMax, dMin, pMax, pMin, step, dTickSize }
}
