import { Controller } from "stimulus"
import Cleave from "cleave.js"
import Skyflow from "skyflow-js"

export default class extends Controller {
  static targets = [
    "secure",
    "signatoryTemplate",
    "signatories",
    "signatory",
    "addSignatoryButton",
  ]

  initialize() {
    this.taxIdCleaves = []
    this.maxSignatories = parseInt(this.element.dataset.max)
    this.collectSSNs = this.element.hasAttribute("data-ssns")

    if (this.skyflowEnabled) {
      this.skyflowClient = Skyflow.init({
        vaultID: SKYFLOW_VAULT_ID, // ID of the vault that the client should connect to.
        vaultURL: SKYFLOW_VAULT_URL, // URL of the vault that the client should connect to.
        getBearerToken: () => {
          return new Promise((resolve, reject) => {
            $.ajax({
              url: this.element.dataset.tokenUrl,
              type: "GET",
              success: function (data) {
                resolve(data)
              },
              error: function (error) {
                reject(error)
              },
            })
          })
        },
        options: {
          logLevel:
            RAILS_ENV === "development"
              ? Skyflow.LogLevel.DEBUG
              : Skyflow.LogLevel.ERROR,
        },
      })
    }

    this.choicesOptions = {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: false,
    }

    this.initForm()

    // clear error states on change
    $(this.element).on("choice", (e) => {
      this.clearFieldError(e.target)
    })
    $(this.element).on("keyup change", "input.form-control", (e) => {
      this.clearFieldError(e.target)
    })

    //pull Skyflow data and populate if this is a revision
    if (this.element.dataset.skyflowId && this.skyflowEnabled) {
      this.skyflowClient
        .getById({
          records: [
            {
              ids: [this.element.dataset.skyflowId],
              table: "ssns",
              redaction: Skyflow.RedactionType.PLAIN_TEXT,
            },
          ],
        })
        .then((response) => {
          this.secureTargets.forEach((el) => {
            const fieldName = el.getAttribute("name")
            const value = response.records[0].fields[fieldName] || ""
            el.value = value
          })
        })
        .catch(() => {
          //alert('Error retrieving secure data.')
        })
    }
  }

  initForm() {
    this.updateSignatoryButtonState()
  }

  get skyflowEnabled() {
    return (
      typeof SKYFLOW_VAULT_ID !== "undefined" &&
      typeof SKYFLOW_VAULT_URL !== "undefined" &&
      this.collectSSNs
    )
  }

  updateSignatoryButtonState() {
    $(this.addSignatoryButtonTarget).toggle(
      this.signatoryTargets.length < this.maxSignatories,
    )
  }

  clearFieldError(el) {
    const $field = $(el).closest(".form-group, td")
    $field.removeClass("error").find(".b-onboarding-field-error").remove()
    $field.find(".field_with_errors").addClass("cleared")
    $field.find("input").removeClass("error")
  }

  signatoryInitials(i) {
    // super hacky
    const $visiblePanel = $(`#signatory-${i}`).find(
      ".b-onboarding-signatory-panel:visible",
    )
    if ($visiblePanel.hasClass("b-onboarding-signatory-select")) {
      const $details = $visiblePanel.find(
        ".b-onboarding-signatory-details-item",
      )
      return `${$details.eq(0).text()[0]}${$details.eq(1).text()[0]}`
    } else if ($visiblePanel.hasClass("b-onboarding-signatory-new")) {
      const $inputs = $visiblePanel.find("input")
      return `${$inputs.eq(0).val()[0]}${$inputs.eq(1).val()[0]}`
    }

    return ""
  }

  // actions

  onSubmit(e) {
    e.preventDefault()

    const $form = $(this.element).find("form")
    let secureDataMissing = false

    const isFieldRequired = (fieldName) => {
      let fieldRequired = true
      if (fieldName == "ssn0") {
        fieldRequired = false
      }
      return fieldRequired
    }

    // store and clear the secure data
    const secureData = Object.fromEntries(
      this.secureTargets
        .map((el) => {
          const $el = $(el)
          const value = $el.val().trim()
          const fieldName = $el.attr("name")

          if (isFieldRequired(fieldName) && (!value || value.length == 0)) {
            //console.log(fieldName);
            secureDataMissing = true
          }

          return [fieldName, value]
        })
        .filter((a) => Boolean(a[1])),
    )

    // hacktastic -- we only ever store ssn1 (individuals) + ssn2 (tenants)
    delete secureData.ssn0
    delete secureData.ssn3
    delete secureData.ssn4
    delete secureData.ssn5

    const bankPrefill = $form.find("[name=bank_prefill]:checked").val()

    const fieldErrorHTML =
      '<div class="b-onboarding-field-error">This field is required.</div>'

    const repopulateSecureData = () => {
      this.secureTargets.forEach((el) => {
        const $el = $(el)
        const fieldName = $el.attr("name")
        const value = secureData[fieldName]
        if (value) {
          $el.val(value)
        } else if (isFieldRequired(fieldName)) {
          $el.closest(".form-group").addClass("error").append(fieldErrorHTML)
        }
      })
    }

    const insecureData = $form
      .find(
        "input:not([data-onboarding-target~=secure]), select:not([data-onboarding-target~=secure])",
      )
      .filter(":not([name=bank_prefill])")
      .fieldSerialize()
    $.post(
      this.element.dataset.validateUrl,
      insecureData,
      (data, textStatus, jqXHR) => {
        if (data || secureDataMissing) {
          // form is not valid
          if (data) {
            $form.replaceWith(data)
            this.initForm()
          } else {
            $form.find("[type=submit]").prop("disabled", false)
          }
          repopulateSecureData()
        } else {
          const completeFormSubmit = () => {
            const formData = $form
              .find("input:not([name=bank_prefill]),select")
              .fieldSerialize()
            repopulateSecureData()

            $.ajax({
              url: $form.attr("action"),
              method: "POST",
              data: formData,
              error: () => {
                alert(
                  "There was an error saving your data.  Please contact an administrator.",
                )
              },
            })
          }

          if (this.skyflowEnabled) {
            const records = {
              records: [
                {
                  table: "ssns",
                  fields: secureData,
                },
              ],
            }

            this.skyflowClient
              .insert(records)
              .then((response) => {
                this.secureTargets.forEach((el) => {
                  const fieldName = el.getAttribute("name")
                  const value = response.records[0].fields[fieldName]
                  if (value) {
                    $form.append(
                      `<input type="hidden" name="investor_entity[${fieldName}]" value="${value}" />`,
                    )
                  }
                })
                $form.append(
                  `<input type="hidden" name="investor_entity[skyflow_ssn_id]" value="${response.records[0].fields["skyflow_id"]}" />`,
                )
                completeFormSubmit()
              })
              .catch((err) => {
                alert(
                  "There was an error saving your data.  Please contact an administrator.",
                )
              })
          } else {
            completeFormSubmit()
          }
        }
      },
    )
  }

  onAddSignatory(e) {
    const $newSignatory = $(this.signatoryTemplateTarget.innerHTML)
      .attr("data-signatory-index", this.signatoryTargets.length + 1)
      .attr("data-controller", "signatory")
      .attr("data-onboarding-signatories-target", "signatory")

    $(this.signatoriesTarget).append($newSignatory)

    this.renumberSignatories()
    this.updateSignatoryButtonState()
  }

  onRemoveSignatory(e) {
    $(e.target).closest(".col-12").remove()
    this.renumberSignatories()
    this.updateSignatoryButtonState()
  }

  renumberSignatories(e) {
    this.signatoryTargets.forEach((el, i) => {
      $(el)
        .find("select, input")
        .each((_, e) => {
          e.name = e.name.replace(
            /^signatories\[\d+\]/,
            `signatories[${i + 1}]`,
          )
          e.id = e.id.replace(/^signatories_\d+/, `signatories_${i + 1}`)

          e.name = e.name.replace(/^ssn\d+/, `ssn${i + 1}`)
          e.id = e.id.replace(/^ssn\d+/, `ssn${i + 1}`)
        })

      $(el)
        .find("label")
        .each((_, e) => {
          e.setAttribute(
            "for",
            e
              .getAttribute("for")
              .replace(/^signatories_\d+/, `signatories_${i + 1}`),
          )
        })

      $(el)
        .find(".b-onboarding-signatory-title")
        .text(`Signatory ${i + 1}`)
    })
  }
}
