import { Controller } from "stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = ["contactsTable", "contactRow"]

  initialize() {
    this.contactsTableTargets.forEach((tableEl) => {
      $(tableEl)
        .find("[name='contacts[][phone_number]']")
        .each((i, phoneEl) => {
          new Cleave(phoneEl, {
            numericOnly: true,
            blocks: [0, 3, 3, 4],
            delimiters: ["(", ") ", "-"],
          })
        })
    })

    // clear error states on change
    $(this.element).on("keyup change", "input.form-control", (e) => {
      this.clearFieldError(e.target)
    })
  }

  clearFieldError(el) {
    const $field = $(el).closest(".form-group, td")
    $field.removeClass("error").find(".b-onboarding-field-error").remove()
    $field.find(".field_with_errors").addClass("cleared")
    $field.find("input").removeClass("error")
  }

  // actions

  onAddContact(e) {
    const $newRow = $(this.contactRowTarget.innerHTML)
    const $table = $(e.target).closest(".b-onboarding-contacts-table")
    const $tbody = $table.find(".b-onboarding-contacts-tbody")

    $newRow.find("input[type=hidden]").val($table.data("contactType"))
    new Cleave($newRow.find("[name='contacts[][phone_number]']").eq(0), {
      numericOnly: true,
      blocks: [0, 3, 3, 4],
      delimiters: ["(", ") ", "-"],
    })

    $tbody.append($newRow)
    if ($tbody.find(".b-onboarding-contact").length >= 5) {
      $table.find(".btn").hide()
    }
  }

  onRemoveContact(e) {
    const $target = $(e.target)
    $target.closest(".b-onboarding-contacts-table").find(".btn").show()
    $target.closest(".b-onboarding-contact").remove()
  }
}
