import { Controller } from "stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["tooltip"]

  connect() {
    if (this.hasTooltipTarget) {
      const tooltip = new bootstrap.Tooltip(this.tooltipTarget)
      tooltip.show()
    }
  }
}
