// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

if (typeof SENTRY_DSN !== "undefined") {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_CURRENT_ENV || "production",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      // Ignore microsoft outlook safe link errors
      // See: https://github.com/getsentry/sentry-javascript/issues/3440
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:/,
    ],
  })
}

if (typeof GOOGLE_ANALYTICS_ID !== "undefined") {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag("js", new Date())
  gtag("config", GOOGLE_ANALYTICS_ID)

  // taken from https://dev.to/tylerlwsmith/using-google-analyticss-gtagjs-with-turbolinks-5coa
  let isInitialLoad = true
  $(document).on("turbolinks:load", () => {
    if (isInitialLoad) {
      isInitialLoad = false
      return
    }
    gtag("config", GOOGLE_ANALYTICS_ID, {
      page_path: window.location.pathname,
    })
  })
}

import { Chart } from "chart.js"

Chart.defaults.font.size = 12
Chart.defaults.color = "#C1C6D0"
Chart.defaults.font.weight = "normal"
Chart.defaults.borderColor = "#E4E9ED"

import "bootstrap"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "../stylesheets/application.scss"
import "jquery-form/dist/jquery.form.min"

import "controllers"

import * as bootstrap from "bootstrap"
$(document).on("turbolinks:load", () => {
  $(document)
    .find('[data-bs-toggle="tooltip"]')
    .each((i, e) => {
      new bootstrap.Tooltip(e)
    })
})

$(window).on("scroll", function () {
  var scrollHeight = $(document).height()
  var scrollPos = $(window).height() + $(window).scrollTop()
  if ((scrollHeight - 300 >= scrollPos) / scrollHeight == 0) {
    $("html").trigger("load-more")
  }
})

import Cookies from "js-cookie"

window.setCID = (cid) => {
  if (cid) {
    Cookies.set("cid", cid)
  } else {
    Cookies.remove("cid")
  }
}
