import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["table", "download", "search", "assetClass"]

  connect() {
    this.currentUrl = window.location.href
    this.downloadUrl = this.downloadTarget.getAttribute("href")
    this.timeout = null
    this.req = null

    new Choices(this.assetClassTarget, {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: true,
    })

    this.$searchTarget = $(this.searchTarget)

    this.$searchTarget.on("change keyup", () => {
      this.updateSearchIcon()
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.doSearch.bind(this), 250)
    })
  }

  updateSearchIcon() {
    this.$searchTarget
      .closest(".form-group")
      .toggleClass("searching", this.$searchTarget.val().length > 0)
  }

  onClearSearch() {
    this.$searchTarget.val("")
    this.updateSearchIcon()
    this.doSearch()
  }

  onChange(e) {
    const $target = $(e.currentTarget)
    if ($target.hasClass("active")) {
      return
    }
    $(this.searchTarget).val("")
    this.downloadUrl = $target.data("download")
    $target.addClass("active").siblings().removeClass("active")

    this.currentUrl = $target.data("url")

    this.doSearch()
    return false
  }

  doSearch() {
    this.updateDownloadLink()

    const data = {
      q: this.searchTarget.value,
      asset_class: this.assetClassTarget.value,
    }

    if (this.req) {
      this.req.abort()
    }

    this.req = $.ajax({
      method: "GET",
      url: this.currentUrl,
      data: data,
      success: (response) => {
        const $table = $(this.tableTarget)
        $table.find('[data-bs-toggle="tooltip"]').tooltip("dispose")
        $table.html(response).find('[data-bs-toggle="tooltip"]').tooltip()
        this.req = null
      },
    })
  }

  updateDownloadLink() {
    $(this.downloadTarget).attr(
      "href",
      `${this.downloadUrl}&q=${this.searchTarget.value}&asset_class=${this.assetClassTarget.value}`,
    )
  }
}
