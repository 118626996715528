import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = [
    "container",
    "input",
    "assetClass",
    "vintage",
    "stage",
    "managed",
    "downloadOverview",
    "downloadFull",
  ]

  connect() {
    this.$el = $(this.element)
    this.timeout = null
    this.req = null
    this.currentSort = "name"
    this.currentSortDirection = "asc"

    const choicesOptions = {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: false,
    }

    this.choices = []

    this.constructor.targets.forEach((target) => {
      if (
        this[
          "has" + target.charAt(0).toUpperCase() + target.slice(1) + "Target"
        ]
      ) {
        const targetEl = this[`${target}Target`]
        this[`$${target}`] = $(targetEl)
        if (targetEl.nodeName == "SELECT") {
          this.choices.push(new Choices(targetEl, choicesOptions))
        }
      }
    })

    this.$input.on("change keyup", () => {
      this.updateSearchIcon()
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.doSearch.bind(this), 250)
    })
  }

  updateSearchIcon() {
    this.$input
      .closest(".form-group")
      .toggleClass("searching", this.$input.val().length > 0)
  }

  onSortTable(e) {
    const sort = e.currentTarget.dataset.sort

    // swap sort direction
    if (this.currentSort == sort) {
      this.currentSortDirection =
        this.currentSortDirection == "asc" ? "desc" : "asc"
    } else {
      // initialize sort direction
      this.currentSortDirection = "asc"
      this.currentSort = sort
    }

    this.doFilter()
  }

  doSearch() {
    if (this.lastSearch !== null && this.$input.val() == this.lastSearch) {
      return
    }

    this.lastSearch = null

    this.doFilter()
  }

  doFilter() {
    const query = this.$input.val()
    const data = {
      q: query,
      asset_class: this.$assetClass.val(),
      vintage: this.$vintage.val(),
      stage: this.$stage.val(),
      managed: this.$managed.val(),
      sort: this.currentSort,
      sort_direction: this.currentSortDirection,
    }

    const params = new URLSearchParams()
    Object.keys(data).forEach((key) => {
      if (data[key].length) {
        params.set(key, data[key])
      }
    })

    if (this.hasDownloadOverviewTarget) {
      this.downloadOverviewTarget.href = `${
        this.element.dataset.url
      }?export=overview&${params.toString()}`
    }
    if (this.hasDownloadFullTarget) {
      this.downloadFullTarget.href = `${
        this.element.dataset.url
      }?export=full&${params.toString()}`
    }

    if (this.req) {
      this.req.abort()
    }

    this.req = $.get({
      url: this.element.dataset.url,
      data: data,
      success: (response) => {
        this.$container.find('[data-bs-toggle="tooltip"]').tooltip("dispose")
        this.$container
          .html(response)
          .find('[data-bs-toggle="tooltip"]')
          .tooltip()
        this.lastSearch = query
        this.req = null
      },
    })
  }

  onClearSearch() {
    this.lastSearch = null
    this.$input.val("")
    this.updateSearchIcon()
    this.doFilter()
  }

  onExpand(e) {
    const $parentRow = $(e.currentTarget).closest("tr")
    const securityId = $parentRow.data("id")
    const expand = !$parentRow.hasClass("expanded")

    // hackery for the sticky table

    const expandRow = ($row) => {
      $row.toggleClass("expanded", expand)

      let $nextRow = $row.next(".b-investments-table-investment")
      while ($nextRow.length > 0) {
        $nextRow = $nextRow
          .toggleClass("expanded", expand)
          .next(".b-investments-table-investment")
      }
    }

    expandRow($parentRow)
    expandRow(
      $parentRow
        .closest(".b-investments-sticky")
        .prev()
        .find(`[data-id=${securityId}]`),
    )
  }
}
