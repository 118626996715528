import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  connect() {
    this.channel = consumer.subscriptions.create(
      {
        channel: "BudgetChannel",
        id: this.element.dataset.entity,
      },
      {
        received: (data) => {
          if (data == "Error") {
            alert("Error processing budget.  Please contact an administrator.")
          } else {
            window.setCID(data)
            window.location.href = this.element.dataset.url
          }
        },
      },
    )
  }
}
