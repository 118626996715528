import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["panel"]

  onWheel(e) {
    this.panelTarget.scrollTo(
      this.panelTarget.scrollLeft + e.deltaX,
      this.panelTarget.scrollTop,
    )
  }
}
