import { Controller } from "stimulus"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["select", "selectContainer", "submitButton"]

  connect() {
    new Choices(this.selectTarget, {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: true,
    })
  }

  onRadioChange(e) {
    if (e.currentTarget.value == "existing") {
      $(this.selectContainerTarget).show()
      $(this.submitButtonTarget).val("Convert Prospect")
    } else {
      $(this.selectContainerTarget).hide()
      $(this.submitButtonTarget).val("Continue")
    }
  }
}
