import { Controller } from "stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["allocationsChart", "tooltip"]

  connect() {
    // current allocations chart
    if (this.hasAllocationsChartTarget) {
      const ctx = this.allocationsChartTarget.getContext("2d")
      const radius = this.allocationsChartTarget.width / 2

      const assetClasses = new Map([
        ["equity", "#184683"],
        ["venture_capital", "#338CFF"],
        ["real_assets", "#61BFFD"],
        ["credit", "#A8DBFF"],
        ["special_situations", "#D7EEFD"],
      ])

      const allocations = JSON.parse(this.data.get("allocations"))
      const total = Object.values(allocations).reduce(
        (acc, v) => acc + parseFloat(v),
        0,
      )

      let startAngle = 0

      assetClasses.forEach((color, assetClass) => {
        if (allocations.hasOwnProperty(assetClass)) {
          allocations[assetClass] = [
            startAngle,
            startAngle + parseFloat(allocations[assetClass]) / total,
          ]

          ctx.fillStyle = color
          ctx.beginPath()
          ctx.moveTo(radius, radius)
          ctx.arc(
            radius,
            radius,
            radius * 0.99,
            (1 - startAngle) * 2 * Math.PI,
            (1 - allocations[assetClass][1]) * 2 * Math.PI,
            true,
          )
          ctx.lineTo(radius, radius)
          ctx.fill()
          ctx.closePath()

          startAngle = allocations[assetClass][1]
        }
      })

      // draw the separator lines
      Object.values(allocations)
        .map((a) => a[1])
        .forEach((ang) => {
          const angle = (1 - ang) * 2 * Math.PI

          ctx.beginPath()
          ctx.moveTo(radius, radius)
          ctx.arc(radius, radius, radius, angle, angle, false)
          ctx.lineWidth = 4
          ctx.strokeStyle = "white"
          ctx.stroke()
          ctx.closePath()
        })

      // draw middle circle
      ctx.beginPath()
      ctx.moveTo(radius, radius)
      ctx.arc(radius, radius, radius * 0.5, 0, 2 * Math.PI, false)
      ctx.fillStyle = "white"
      ctx.fill()
      ctx.closePath()

      $(this.allocationsChartTarget).mousemove((e) => {
        // page offset of the canvas
        const offset = $(this.allocationsChartTarget).offset()

        // position within canvas (in document coordinates)
        const eventCanvasOffset = {
          x: e.pageX - offset.left,
          y: e.pageY - offset.top,
        }

        const p = ctx.getImageData(
          (eventCanvasOffset.x * this.allocationsChartTarget.width) /
            this.allocationsChartTarget.offsetWidth,
          (eventCanvasOffset.y * this.allocationsChartTarget.height) /
            this.allocationsChartTarget.offsetHeight,
          1,
          1,
        ).data

        const hexString = `#${this.componentToHex(p[0])}${this.componentToHex(
          p[1],
        )}${this.componentToHex(p[2])}`.toUpperCase()

        let assetClass = null

        for (const [k, v] of assetClasses) {
          if (v == hexString) {
            assetClass = k
          }
        }

        this.tooltipTargets.forEach((el) => {
          const tooltip = bootstrap.Tooltip.getInstance(el)
          if (el.dataset.tooltip == assetClass) {
            // find where to position the tooltip
            const position =
              allocations[assetClass][0] +
              (allocations[assetClass][1] - allocations[assetClass][0]) / 2

            const x = Math.cos(Math.PI * 2 * (1 - position))
            const y = Math.sin(Math.PI * 2 * (1 - position))

            el.style.left = `${
              this.allocationsChartTarget.offsetLeft +
              this.allocationsChartTarget.offsetWidth / 2 +
              this.allocationsChartTarget.offsetWidth * 0.375 * x
            }px`
            el.style.top = `${
              this.allocationsChartTarget.offsetTop +
              this.allocationsChartTarget.offsetHeight / 2 +
              this.allocationsChartTarget.offsetHeight * 0.375 * y
            }px`

            tooltip.show()
          } else {
            tooltip.hide()
          }
        })
      })

      $(this.allocationsChartTarget).mouseleave((e) => {
        this.tooltipTargets.forEach((el) =>
          bootstrap.Tooltip.getInstance(el).hide(),
        )
      })
    }
    // end current allocations chart
  }

  componentToHex(c) {
    const hex = c.toString(16)
    return hex.length == 1 ? "0" + hex : hex
  }
}
