import { Controller } from "stimulus"
import Cleave from "cleave.js"
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["select", "commitment"]

  connect() {
    this.choicesOptions = {
      searchEnabled: true,
      searchResultLimit: 100,
      itemSelectText: "",
      allowHTML: true,
    }

    this.selectTargets.forEach((el) => {
      new Choices(el, this.choicesOptions)
    })

    new Cleave(this.commitmentTarget, {
      numeral: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 0,
    })
  }
}
