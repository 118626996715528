export function displaySkyflowError(errorResponse) {
  if ("error" in errorResponse) {
    let details = errorResponse.error.description ?? "unknown error"
    window.alert(`There was an error saving your data: ${details}`)
  } else {
    if (errorResponse instanceof Error) {
      window.alert(
        `There was an unexpected error saving your data: ` +
          errorResponse.message,
      )
    } else {
      window.alert(
        `There was an unexpected error saving your data: ` + JSON.stringify(er),
      )
    }
  }
}

export function isSkyflowMissingFileError(errorResponse) {
  if ("error" in errorResponse) {
    let description = errorResponse.error.description
    return (
      description.includes("No files were found") ||
      description.includes("No Files found")
    )
  }
  return false
}
