import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize() {
    $(this.element).on("click", "svg", (e) => {
      const $parent = $(e.currentTarget.closest(".b-account-field"))
      if ($parent.hasClass("active")) {
        $parent.removeClass("active")
        $parent.find("input").attr("type", "password")
      } else {
        $parent.addClass("active")
        $parent.find("input").attr("type", "text")
      }
    })
  }
}
