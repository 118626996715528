import { Controller } from "stimulus"
import Choices from "choices.js"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = ["remove", "select", "phone", "ssn"]

  connect() {
    $(this.element)
      .find(".b-onboarding-signatory-panel:visible")
      .each((i, el) => {
        this.moveSSNFieldsToPanel($(el))
      })

    new Cleave(this.phoneTarget, {
      numericOnly: true,
      blocks: [0, 3, 3, 4],
      delimiters: ["(", ") ", "-"],
    })

    if (this.hasSsnTarget) {
      new Cleave(this.ssnTarget, {
        blocks: [3, 2, 4],
        delimiter: "-",
        numericOnly: true,
      })
    }

    this.signatoryChoices = JSON.parse(this.element.dataset.signatories).map(
      (signatory) => {
        return {
          value: signatory.id,
          label: `${signatory.last_name}, ${signatory.first_name}`,
          customProperties: {
            firstName: signatory.first_name,
            lastName: signatory.last_name,
            email: signatory.email,
            title: signatory.title,
            phoneNumber: signatory.phone,
          },
        }
      },
    )

    const choices = new Choices(this.selectTarget, {
      searchEnabled: true,
      searchResultLimit: 100,
      itemSelectText: "",
      allowHTML: true,
    })

    choices.setChoices(this.signatoryChoices, "value", "label")

    const updateSignatoryDetails = (choice) => {
      const $details = $(this.selectTarget)
        .closest(".b-onboarding-signatory-select")
        .find(".b-onboarding-signatory-details")
      $details.addClass("filled")

      const $detailItems = $details.find(".b-onboarding-signatory-details-item")
      $detailItems.eq(0).text(choice.customProperties.firstName)
      $detailItems.eq(1).text(choice.customProperties.lastName)
      $detailItems.eq(2).text(choice.customProperties.email)
      $detailItems.eq(3).text(choice.customProperties.title)
      $detailItems.eq(4).text(choice.customProperties.phoneNumber)
    }

    if (this.selectTarget.dataset.selected) {
      choices.setChoiceByValue(this.selectTarget.dataset.selected)
      updateSignatoryDetails(choices.getValue())
    }

    this.selectTarget.addEventListener("choice", (e) => {
      updateSignatoryDetails(e.detail.choice)
    })
  }

  onRadioChange(e) {
    const $el = $(e.currentTarget)
    const $parent = $el.closest(".b-onboarding-signatory")
    $parent.find(".b-onboarding-signatory-panel").hide()

    const $visiblePanel = $parent.find(
      ".b-onboarding-signatory-" + e.currentTarget.value,
    )

    if ($visiblePanel.length > 0) {
      $visiblePanel.show()

      // move the SSN input to the visible panel
      this.moveSSNFieldsToPanel($visiblePanel)
    }
  }

  moveSSNFieldsToPanel($panel) {
    $panel
      .closest(".b-onboarding-signatory")
      .find(".b-onboarding-ssn")
      .appendTo($panel.find(".b-js-onboarding-ssn-target"))
  }
}
