import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["container", "article"]

  onFilter(e) {
    const val = $(e.currentTarget).val()
    location.href = !val ? "/news" : `/news/investment/${val}`
  }

  onToggleLayout(e) {
    e.preventDefault()
    const $button = $(e.currentTarget)

    if ($button.hasClass("active")) {
      return
    }

    const newLayout = $button.data("layout")

    $.post(this.element.dataset.layoutUrl, { layout: newLayout })
    $(this.containerTarget)
      .removeClass("articles-layout-grid articles-layout-list")
      .addClass(`articles-layout-${newLayout}`)

    $button.parent().children().removeClass("active")
    $button.addClass("active").blur()
  }
}
