import { Controller } from "stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  static targets = [
    "dollarField",
    "totalField",
    "allocationField",
    "selectAll",
    "investment",
  ]

  connect() {
    this.dollarFieldTargets.forEach((field) => {
      new Cleave(field, {
        numeral: true,
      })
    })

    $(this.allocationFieldTargets).on(
      "keyup change",
      this.updateTotal.bind(this),
    )
    this.updateTotal()
  }

  updateTotal() {
    if (this.hasTotalFieldTarget) {
      $(this.totalFieldTarget).val(
        this.allocationFieldTargets.reduce(
          (total, field) => total + parseFloat(field.value || 0),
          0,
        ),
      )
    }
  }

  selectAll() {
    $(this.investmentTargets).prop("checked", this.selectAllTarget.checked)
  }

  investmentChanged() {
    this.selectAllTarget.checked =
      $(this.investmentTargets).filter(":not(:checked)").length == 0
  }
}
