import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="lp-onboarding"
export default class extends Controller {
  static targets = ["advisorSelect", "nonG2Advisor", "g2Advisor"]

  connect() {
    this.updateAdvisor()
  }

  updateAdvisor() {
    const sideLetterId = document.getElementById("side_letter_id").value
    const isQpcsG2 = this.isQpcsG2SideLetter(sideLetterId)

    if (isQpcsG2) {
      this.nonG2AdvisorTarget.style.display = "none"
      this.g2AdvisorTarget.style.display = "block"
    } else {
      this.nonG2AdvisorTarget.style.display = "block"
      this.g2AdvisorTarget.style.display = "none"
    }
  }

  isQpcsG2SideLetter(sideLetterId) {
    const g2SideLetterIds = JSON.parse(
      this.element.dataset.lpOnboardingG2SideLetters || "[]",
    )
    return g2SideLetterIds.includes(sideLetterId)
  }
}
