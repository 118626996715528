import { Controller } from "stimulus"
import Choices from "choices.js"
import Skyflow from "skyflow-js"

export default class extends Controller {
  static targets = [
    "tableContainer",
    "filter",
    "modal",
    "modalContent",
    "loader",
    "download",
    "reason",
    "search",
  ]

  initialize() {
    this.currentSort = this.element.dataset.sort
    this.currentSortDirection = this.element.dataset.sortDir
    this.currentFilters = {}
    this.currentPage = 1
    this.timeout = null
    this.req = null

    const choicesOptions = {
      searchEnabled: true,
      searchResultLimit: 100,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: true,
    }

    if (this.hasSearchTarget) {
      $(this.searchTarget).on("change keyup", () => {
        this.updateSearchIcon()
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.updateTable.bind(this), 250)
      })
    }

    this.filterChoices = this.filterTargets.map((el) => {
      $(el).on("change", (e) => {
        this.applyFilters()
      })

      return new Choices(el, choicesOptions)
    })

    $(this.element).on("click", ".pagination a", (e) => {
      e.preventDefault()
      const url = new URL(
        e.currentTarget.getAttribute("href"),
        window.location.origin,
      )
      this.currentPage = url.searchParams.get("page") || 1
      this.updateTable()
    })

    if (this.hasReasonTarget) {
      $(this.reasonTarget).on("keyup change", () => {
        $(this.modalTarget)
          .find(".btn")
          .prop("disabled", this.reasonTarget.value == "")
      })
    }
  }

  connect() {
    const activeTab = $(this.element).find(".b-manage-status-tab.active").get(0)
    if (activeTab) {
      activeTab.parentElement.scrollLeft =
        activeTab.offsetLeft - window.innerWidth / 2 + activeTab.offsetWidth / 2
    }
  }

  updateSearchIcon() {
    $(this.searchTarget)
      .closest(".form-group")
      .toggleClass("searching", this.searchTarget.value.length > 0)
  }

  updateTable() {
    const data = {
      page: this.currentPage,
      ...this.currentFilters,
    }

    if (this.currentSort) {
      data.sort = this.currentSort
      data.dir = this.currentSortDirection
    }

    if (this.hasDownloadTarget) {
      const params = new URLSearchParams()
      Object.keys(data).forEach((key) => {
        if (data[key].length) {
          params.set(key, data[key])
        }
      })

      this.downloadTarget.href = `${
        this.element.dataset.url
      }?export=1&${params.toString()}`
    }

    if (this.hasSearchTarget) {
      data.q = this.searchTarget.value
    }

    if (this.req) {
      this.req.abort()
    }

    this.req = $.get(this.element.dataset.url, data, (response) => {
      $(this.tableContainerTarget).html(response)
      this.req = null
    })
  }

  applyFilters() {
    this.currentPage = 1
    this.currentFilters = {}

    this.filterTargets.forEach((el) => {
      if (el.value.length) {
        this.currentFilters[el.getAttribute("name")] = el.value
      }
    })

    this.updateTable()
  }

  onSortTable(e) {
    const sort = e.currentTarget.dataset.sort

    if (this.currentSort == sort) {
      this.currentSortDirection =
        this.currentSortDirection == "asc" ? "desc" : "asc"
    } else {
      this.currentSortDirection = "asc"
      this.currentSort = sort
    }

    this.currentPage = 1

    this.updateTable()
  }

  onReviewOnboarding(e) {
    const tokenUrl = e.currentTarget.dataset.tokenUrl

    const $modal = $(this.modalTarget)
    const $modalContent = $(this.modalContentTarget)
    $modal.find(".loader").show()
    $modalContent.hide()
    $modal.modal("show")

    $.get(e.currentTarget.dataset.onboardingUrl, (response) => {
      $modalContent.html(response)

      const finishLoading = () => {
        $modal.find(".loader").hide()
        $modalContent.show()
      }

      if (
        typeof SKYFLOW_VAULT_ID !== "undefined" &&
        typeof SKYFLOW_VAULT_URL !== "undefined"
      ) {
        const skyflowClient = Skyflow.init({
          vaultID: SKYFLOW_VAULT_ID, // ID of the vault that the client should connect to.
          vaultURL: SKYFLOW_VAULT_URL, // URL of the vault that the client should connect to.
          getBearerToken: () => {
            return new Promise((resolve, reject) => {
              $.ajax({
                url: tokenUrl,
                type: "GET",
                success: function (data) {
                  resolve(data)
                },
                error: function (error) {
                  reject(error)
                },
              })
            })
          },
          options: {
            logLevel:
              RAILS_ENV === "development"
                ? Skyflow.LogLevel.DEBUG
                : Skyflow.LogLevel.ERROR,
          },
        })

        skyflowClient
          .detokenize({
            records: $("[data-token]")
              .get()
              .map((el) => {
                return { token: el.dataset.token }
              })
              .filter(Boolean),
          })
          .then((response) => {
            response.records.forEach((record) => {
              $(`[data-token=${record.token}]`).append(record.value)
            })
          })
          .finally(() => {
            finishLoading()
          })
      } else {
        finishLoading()
      }
    })
  }

  onSubmitOnboarding(e) {
    if (
      $(e.submitter).val() == "reject" &&
      $(e.target).find("[name=reason]").val() == ""
    ) {
      e.preventDefault()
      alert(
        "You must provide a reason for rejecting this onboarding, which will be provided to the submitter.",
      )
    }
  }

  onEnvelopeClear(e) {
    $.ajax({
      url: e.currentTarget.dataset.url,
      method: "DELETE",
      success: () => {
        this.updateTable()
      },
    })
  }

  onDocuSign(e) {
    $.ajax({
      url: e.currentTarget.dataset.url,
      method: "GET",
      success: (response) => {
        window.location.href = response
      },
      error: () => {
        alert("There was an error retreiving the DocuSign link.")
      },
    })
  }

  onResendDocuSign(e) {
    const $button = $(e.currentTarget)
    const $span = $("<span>Sending...</span>")
    const url = $button.data("url")
    $button.replaceWith($span)

    $.ajax({
      url: url,
      method: "POST",
      success: (response) => {
        $span.replaceWith('<span class="success">Sent!</span>')
      },
      error: () => {
        $span.replaceWith('<span class="error">Error</span>')
      },
    })
  }

  onApproveInvestment(e) {
    const investmentId = $(e.currentTarget).parent().data("investment")
    const $container = $(this.element).find(
      `.b-status-buttons-container[data-investment=${investmentId}]`,
    )

    $container.html(this.loaderTarget.innerHTML)

    $.ajax({
      url: $container.data("url"),
      method: "POST",
      success: () => {
        $container.html('<span class="success">Approved</span>')
      },
      error: () => {
        $container.html('<span class="error">Error</span>')
      },
    })
  }

  onRejectInvestment(e) {
    this.rejectionId = $(e.currentTarget).parent().data("investment")
    $(this.reasonTarget).val("")
    $(this.modalTarget).find(".btn").prop("disabled", true)
    $(this.modalTarget).modal("show")
  }

  onCompleteRejectInvestment() {
    $(this.modalTarget).modal("hide")
    const $container = $(this.element).find(
      `.b-status-buttons-container[data-investment=${this.rejectionId}]`,
    )
    $container.html(this.loaderTarget.innerHTML)

    $.ajax({
      url: $container.data("url"),
      method: "POST",
      data: { reason: this.reasonTarget.value },
      success: () => {
        $container.html('<span class="error">Rejected</span>')
      },
      error: () => {
        $container.html('<span class="error">Error</span>')
      },
    })
  }

  onClearFilters(e) {
    e.currentTarget.blur()
    this.filterChoices.forEach((choice) => {
      choice.setChoiceByValue("")
    })
    this.applyFilters()
  }

  onClearSearch() {
    this.searchTarget.value = ""
    this.updateSearchIcon()
    this.updateTable()
  }

  onViewBudget(e) {
    window.setCID(e.currentTarget.dataset.cid)
  }
}
