import { Controller } from "stimulus"
import Choices from "choices.js"
import Cleave from "cleave.js"
import Skyflow from "skyflow-js"

export default class extends Controller {
  static targets = [
    "households",
    "createHousehold",
    "newHouseholdName",
    "modal",
    "advisor",
  ]

  initialize() {
    this.choicesOptions = {
      searchEnabled: false,
      itemSelectText: "",
      allowHTML: true,
      shouldSort: false,
    }

    this.advisorSelect = new Choices(this.advisorTarget, this.choicesOptions)

    this.initForm()

    $(this.modalTarget).on("hidden.bs.modal", () => {
      this.resetHouseholdForm()
    })

    // clear error states on change
    $(this.element).on("choice", (e) => {
      this.clearFieldError(e.target)
    })
    $(this.element).on("keyup change", "input.form-control", (e) => {
      this.clearFieldError(e.target)
    })
  }

  initForm() {
    this.householdSelect = new Choices(
      this.householdsTarget,
      this.choicesOptions,
    )

    $(this.newHouseholdNameTarget).on("keyup change", () => {
      this.clearHouseholdErrorState()
      $(this.createHouseholdTarget).prop(
        "disabled",
        this.newHouseholdNameTarget.value == "",
      )
    })
  }

  clearHouseholdErrorState() {
    $(this.newHouseholdNameTarget).removeClass("error")
    $(".modal .b-onboarding-field-error").remove()
  }

  resetHouseholdForm() {
    this.clearHouseholdErrorState()
    $(this.newHouseholdNameTarget).val("")
    $(this.createHouseholdTarget).prop("disabled", true)
  }

  clearFieldError(el) {
    const $field = $(el).closest(".form-group, td")
    $field.removeClass("error").find(".b-onboarding-field-error").remove()
    $field.find(".field_with_errors").addClass("cleared")
    $field.find("input").removeClass("error")
  }

  // actions

  onCreateHousehold(e) {
    const householdName = this.newHouseholdNameTarget.value

    $.ajax({
      method: "POST",
      url: this.element.dataset.householdUrl,
      data: {
        name: householdName,
        owner: $("select[name=new_household_advisor").val(),
      },
      success: (response) => {
        this.householdSelect.setChoices(
          [{ value: householdName, selected: true }],
          "value",
          "value",
        )
        $(this.modalTarget).modal("hide")
      },
      error: () => {
        this.clearHouseholdErrorState()
        $(this.newHouseholdNameTarget).addClass("error")
        $(
          '<div class="b-onboarding-field-error">Household name already exists. Please enter a new name.</div>',
        ).insertAfter(this.newHouseholdNameTarget)
      },
    })
  }
}
